import React, { useEffect } from 'react';
import { Button, Form, Row, Col } from 'antd';
import { InputField, DateField, SelectField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';

export default (props) => {
  const { searchVal } = props;
  const { control, handleSubmit, setValue } = useForm()

  const searchStatusLabel = (searchVal?.status === "" || searchVal?.status) ? true : false
  const searchOutletLabel = (searchVal?.outlet === "" || searchVal?.outlet) ? true : false

  useEffect(() => {
    if (searchVal) {
      if (searchVal?.outlet) {
        setValue("outlet", { label: props.field2.find(y => y.value.toString() === searchVal?.outlet)?.label, value: searchVal?.outlet });
      }
      if (searchVal?.netsuite_synced) {
        setValue("netsuite_synced", { label: searchVal?.netsuite_synced === "1" ? "Netsuite Synced: Yes" : "Netsuite Synced: No", value: searchVal?.netsuite_synced });
      }
      if (searchVal?.date) {
        setValue("date", dayjs(searchVal?.date));
      }
    }
  }, [searchVal, props]);

  const onSubmit = (val) => {
    props.onSearch(val);
  }

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="inline" className='w-100 inline-form'>
      <Row className='w-100' gutter={[10, 10]}>
        <Col flex="auto">
          <InputField
            fieldname='reference'
            label=''
            class='mb-0 w-100'
            initValue={searchVal?.reference ? searchVal?.reference : ''}
            control={control}
            iProps={{ placeholder: 'Search by SC No.' }}
          />
        </Col>
        <Col flex="auto">
          <DateField
            fieldname='date'
            label=''
            initValue={searchVal?.date ? dayjs(searchVal?.date) : ''}
            control={control}
            class='mb-0 w-10'
            iProps={{ placeholder: 'SC Date', size: 'large' }}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='status'
            label=''
            class='mb-0 w-10 default-select'
            initValue={searchStatusLabel ? {label: searchVal?.status === "" ? 'All Status' : searchVal?.status, value: searchVal?.status} : ''}
            control={control}
            iProps={{ placeholder: 'Status' }}
            selectOption={props.field1}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='outlet'
            label=''
            class='mb-0 w-10 default-select'
            initValue={searchOutletLabel ? {label: searchVal?.outlet === "" ? 'All Outlets' : searchVal?.outlet, value: searchVal?.outlet} : ''}
            control={control}
            iProps={{ placeholder: 'Outlet' }}
            selectOption={props.field2}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='netsuite_synced'
            label=''
            class='mb-0 w-100 default-select'
            initValue={{ label: "Netsuite Synced: All", value: "" }}
            control={control}
            selectOption={[{
              label: "Netsuite Synced: All",
              value: ""
            }, {
              label: "Netsuite Synced: No",
              value: "0"
            }, {
              label: "Netsuite Synced: Yes",
              value: "1"
            }]}
            iProps={{ placeholder: 'Netsuite Synced' }}
          />
        </Col>
        <Col flex="70px">
          <Button className='w-100' size='large' type='primary' htmlType='submit'>Search</Button>
        </Col>
      </Row>
    </Form>
  )
}