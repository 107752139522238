import React, { useEffect, useState } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Empty } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { bulkCreateLocation, removeLocation, bulkRemoveLocation } from '../../../../../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import { SelectField, InputField, CheckboxGroup } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, meta, activeTab, allowedEdit, allowedDelete, reloadApi, data } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const menuType = useSelector((state) => state.product.menuType);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [hasDirty, setHasDirty] = useState(false);
  // const [data, setData] = useState([])
  const [locationsData, setLocationsData] = useState([])
  const [locationsMeta, setLocationsMeta] = useState([])
  // const locationCheckBoxes = locationsMeta?.map(({id, desc}) => ({label: desc, value: id}))
  const [searchValue, setSearchValue] = useState('');


  const colName = [
    {
      title: 'Location Code',
      dataIndex: 'location_code',
      key: 'location_code',
      className: 'enable-click',
      sorter: (a,b) => a.location_code.localeCompare(b.location_code)
    },
    {
      title: 'Location Desc',
      dataIndex: 'location_desc',
      key: 'location_desc',
      className: 'enable-click',
      sorter: (a,b) => a.location_desc.localeCompare(b.location_desc)
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 200,
      render: (text, record) =>
        <ActionButton
          title="Location"
          recordId={record.id}
          onRemove={onRemove}
          setLoading={false}
          loading={false}
        />
    }
  ];

  const addNew = () => { 
    setVisible(true);
    setValue('search', '');
    setValue('locations', '');
  }

  const btnList = [
    {
      title: "Are you sure to reorder the list?",
      text: "Update Listing",
      classes: 'b-success attendance-success',
      btnHidden: !hasDirty,
      action: () => onUpdateList()
    },
    {
      title: 'Are you sure to remove the selected Location(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ New Location',
      classes: 'attendance-success',
      action: () => addNew(),
    },
  ];

  // New
  useEffect(() => {
    if (meta && 'locations' in meta) {
      let temp = [];
      meta?.roles?.map(role => {
        temp.push({
          label: role.name,
          value: role.id
        })
      })
    }

    if(data && 'locations' in data) {
      setLocationsData(data?.locations)
    }

    if(meta && 'locations' in meta) {
      setLocationsMeta(meta?.locations?.map(({id, desc, code}) => ({label: `${code} ${desc}`, value: id})))
    }
  }, [data, meta]);


  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      reloadApi()
    } else {
      reloadApi()
    }
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRemove = async (locationId) => {
    // props.setLoading(true);

    const payload = {
      location_id: locationId,
      status_event: 'remove'
    }

    await removeLocation(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Location has been removed successfully!");
        reloadApi()
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      id: id,
      locations: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveLocation(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Locations has been removed successfully!");
        reloadApi()
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onFinish = async (val) => {
    if(!locationsMeta?.length) return

    props.setLoading(true);
    const payload = {
      locations: JSON.stringify(val.locations) || "[]",
      id: id
    }

    await (bulkCreateLocation)(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(`Locations created successfully.`);
        setVisible(false);
        setValue('search', '');
        setValue('locations', '');
        reloadApi()
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  const searchLocation = () => {
    if(searchValue !== '') {
      setLocationsMeta(meta?.locations?.filter(item => item?.code?.toLowerCase()?.includes(searchValue?.toLowerCase()) || item?.desc?.toLowerCase().includes(searchValue.toLowerCase())).map(({id, desc, code}) => ({label: `${code} ${desc}`, value: id})))
    } else {
      setLocationsMeta(meta?.locations?.map(({id, desc, code}) => ({label: `${code} ${desc}`, value: id})))
    }
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: 
    // <Spin indicator={antIcon} size="large" spinning={props.loading}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={15} className='w-100'>
          <Title level={4} className='m-0'>
            Create Locations
          </Title>
          <Row className='w-100' gutter={[10, 10]}>
            <Col flex="auto">
              <InputField
                fieldname='search'
                label=''
                class='mb-0 w-100'
                initValue={''}
                control={control}
                iProps={{ placeholder: 'Search for locations' }}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </Col>
            <Col flex="70px">
              <Button className='w-100' size='large' type='primary' onClick={searchLocation}>Search</Button>
            </Col>
          </Row>
          
          {locationsMeta?.length === 0 ?
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            :
            <CheckboxGroup
              disabled={!allowedEdit}
              fieldname={'locations'}
              label={''}
              class={`mb-1 fullWidth-checbox`}
              control={control}
              initValue={[]}
              option={locationsMeta}
              rules={{
                required: 'Location is required',
              }}
              validate={errors.locations && 'error'}
              validMessage={errors.locations && errors.locations.message}
            />
          }
          <Row gutter={10} justify={'center'}>
            <Col span={locationsMeta?.length === 0 ? 24 : 12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('search', '');
              setValue('locations', '');
            }}>Cancel</Button></Col>
            {
              (locationsMeta?.length > 0) && allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>,
    width: 1000,
    onCancel: () => {
      setVisible(false);
      setValue('search', '');
      setValue('locations', '');
    },
  };

  const onUpdateList = async () => {
    props.setLoading(true);

    const payload = {
      menu_types: JSON.stringify(data?.map((d, i) => ({ id: d.id, position: i + 1 })))
    }
    await updateMenuTypesOrder(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Menu Types Order updated successfully.");
        setHasDirty(false);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={""} btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            // setHasDirty={setHasDirty}
            // setDataList={setData}
            list={locationsData}
            classes="nopad"
            ListData={locationsData?.map(x => ({ ...x, key: x.id }))}
            // onChange={onTableChange}
            ListCol={colName}
            rowSelection={rowSelection}
            pagination={{
              total: menuType?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}