// SW ITEM
import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Empty } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import FormGroup from 'Molecules/FormGroup';
import { useDispatch, useSelector } from 'react-redux';
import { getSwItemList, patchSwItemList } from '../../../../../ducks/actions'
import { editSwItem, addSwItem, removeSwItem } from '../../../../../ducks/services'
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import Search from './Search'
import ActionButton from 'Molecules/ActionButton';

const _ = require('lodash');

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, activeTab, data, allowedEdit, allowedDelete, reloadCount } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const poItems = useSelector((state) => state.inventory.swItems);
  const meta = useSelector((state) => state.inventory.swItemsOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [load, setLoad] = useState(false);
  const [visible, setVisible] = useState(false);
  const [searchVal, setSearchVal] = useState();
  const editable = data?.status === "pending" && allowedEdit;
  const [selectedItem, setSelectedItem] = useState(null);
  const [isSemiProduct, setIsSemiProduct] = useState(false);
  const [isIngredient, setIsIngredient] = useState(true);

  const colName = [
    {
      title: 'No.',
      dataIndex: 'index',
      key: 'index',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'Item',
      dataIndex: 'desc',
      key: 'desc',
      sorter: true,
      className: 'enable-click',
      render: (text, record) => <span>{record.code} {text}</span>
    },
    {
      title: 'UOM',
      dataIndex: 'uom_code',
      key: 'uom_code',
      sorter: true,
      className: 'enable-click',
      render: (text, record) => record?.is_semi_product === "1" ? record?.is_ingredient === "1" ? text : "-" : text
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'Reason',
      dataIndex: 'reason_code',
      key: 'reason_code',
      sorter: true,
      className: 'enable-click',
      align: 'center',
      render: (text, record) => <span>{text} {record.reason_desc}</span>
    },
    {
      title: 'Is Ingredient',
      dataIndex: 'is_ingredient',
      key: 'is_ingredient',
      sorter: true,
      className: 'enable-click',
      align: 'center',
      render: (text, record) => record?.is_semi_product === "1" ? text === "1" ? "Yes" : "-" : "No"
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="Stock Wastage Item"
          btnAction1={editable ? () => {
            setVisible(true);
            setValue('id', record.id);
            setValue('item', record.product_id ? { label: `${record.code} ${record.desc}`, value: record.product_id } : '');
            setValue('quantity', record.quantity);
            setValue('reason', record.reason_id ? { label: `${record.reason_code} ${record.reason_desc}`, value: record.reason_id } : '');
            setSelectedItem(record.product_id);
            setIsSemiProduct(record.is_semi_product === "1");
            setValue('is_ingredient', record.is_ingredient === "1");
            setIsIngredient(record.is_semi_product === "1" ? record.is_ingredient === "1" ? true : false : true);
            setValue('uom', record.uom_id ? { label: record.uom_code, value: record.uom_id } : "");
          } : false}
          onRemove={(data?.status === "pending" && allowedDelete) && onRemove}
          recordId={record.id}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const formFields = [
    {
      name: 'id',
      label: '',
      type: 'input',
      hidden: true,
    },
    {
      name: 'item',
      label: 'Item',
      req: true,
      placeholder: 'Please select',
      type: 'select',
      class: 'default-select',
      options: _.map(meta?.products, (e) => ({ ...e, label: `${e.code} ${e.name}`, value: e.id })),
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      onChange: (e) => {
        setSelectedItem(e.value);
        let baseUom = e.product_uoms?.find(x => x.is_base_uom === "1");
        setValue('uom', baseUom ? { label: baseUom?.code, value: baseUom?.id } : '');
        if (e.is_semi_product === "1") {
          setIsSemiProduct(true)
          setIsIngredient(true)
          setValue("is_ingredient", true);
        } else {
          setIsSemiProduct(false)
          setIsIngredient(true)
          setValue("is_ingredient", false);
        }
      }
    },
    {
      name: 'quantity',
      label: 'Quantity',
      req: true,
      placeholder: 'Please select',
      type: 'input',
      number: true,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
    },
    {
      name: 'reason',
      label: 'Reason',
      req: false,
      placeholder: 'Please select',
      class: 'default-select',
      type: 'select',
      options: _.map(meta?.reasons, (e) => ({ label: `${e.code} ${e.desc}`, value: e.id })),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required'
    },
    {
      name: 'uom',
      label: 'UOM',
      req: false,
      placeholder: 'Please select',
      type: 'select',
      class: 'default-select',
      options: meta?.products?.find(item => item.id === selectedItem)?.product_uoms.map(item => ({label: item.code, value: item.id})),
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: true,
      hidden: !isIngredient
    },
    {
      name: 'is_ingredient',
      label: 'Is Ingredient?',
      req: false,
      type: 'switch',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      hidden: !isSemiProduct,
      onChange: (e) => {
        setIsIngredient(e)
      }
    },
  ];

  useEffect(() => {
    if (activeTab === "2")
      dispatch(getSwItemList(1, limit, '', '', null, setLoad, id));
  }, [activeTab]);

  const addNew = () => {
    setVisible(true);
    setValue('id', '');
    setValue('item', '');
    setValue('reason', '');
    setValue('quantity', '');
    setIsSemiProduct(false);
    setValue('is_ingredient', false);
    setIsIngredient(true);
    setSelectedItem(null);
    setValue('uom', '');
  }

  const btnList = [
    {
      text: '+ New Item',
      classes: 'attendance-success',
      action: () => addNew(),
    }
  ];

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getSwItemList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading, id));
    } else {
      dispatch(getSwItemList(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading, id));
    }
  }
  
  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        product_code: search?.product_code
      };
      setSearchVal(searching);
      dispatch(getSwItemList(1, limit, '', '', searching, props.setLoading, id));
    } else {
      setSearchVal(null);
      dispatch(getSwItemList(1, limit, '', '', null, props.setLoading, id));
    }
  };

  const onFinish = async (val) => {
    setLoad(true);

    const payload = {
      reason_id: val?.reason?.value ? val?.reason?.value : "",
      quantity: val?.quantity,
      product_id: val?.item?.value,
      written_off_item_id: val?.id,
      written_off_id: id,
      uom_id: val?.uom?.value,
      is_ingredient: isSemiProduct ? val?.is_ingredient ? "1" : "0" : "0",
    }
    await (val?.id ? editSwItem : addSwItem)(payload).then(res => {
      setLoad(false);
      if(res.data.code === 200) {
        message.success(`SW Item ${val?.id ? 'Updated' : 'Added'} Successfully!`);
        setVisible(false);
        setPage(1);
        reloadCount();
        dispatch(patchSwItemList(poItems, res.data.data, meta));
      }else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  const onRemove = async (recordId) => {
    setLoad(true);

    const payload = {
      written_off_item_id: recordId,
      written_off_id: id
    }
    await removeSwItem(payload).then(res => {
      setLoad(false);
      if(res.data.code === 200) {
        message.success(`SW Item Removed Successfully!`);
        setVisible(false);
        setPage(1);
        reloadCount();
        setTimeout(() => dispatch(getSwItemList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      }else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            {getValues('id') ? 'Edit' : 'Add'} SW Item
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('id', '');
              setValue('item', '');
              setValue('reason', '');
              setValue('quantity', '');
              setIsSemiProduct(false);
              setValue('is_ingredient', false);
              setIsIngredient(true);
              setSelectedItem(null);
              setValue('uom', '');
            }}>Cancel</Button></Col>
            {
              editable &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setValue('id', '');
      setValue('reason', '');
      setValue('item', '');
      setValue('quantity', '');
      setIsSemiProduct(false);
      setValue('is_ingredient', false);
      setIsIngredient(true);
      setSelectedItem(null);
      setValue('uom', '');
    },
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click") && editable){
          setValue('id', record.id);
          setValue('item', record.product_id ? { label: `${record.code} ${record.desc}`, value: record.product_id } : '');
          setValue('reason', record.reason_id ? { label: `${record.reason_code} ${record.reason_desc}`, value: record.reason_id } : '');
          setValue('quantity', record.quantity);
          setSelectedItem(record.product_id);
          setIsSemiProduct(record.is_semi_product === "1");
          setValue('is_ingredient', record.is_ingredient === "1");
          setIsIngredient(record.is_semi_product === "1" ? record.is_ingredient === "1" ? true : false : true);
          setValue('uom', record.uom_id ? { label: record.uom_code, value: record.uom_id } : "");
          setVisible(true);
        }
      },
    };
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={load}>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={''} btnList={editable ? btnList : []} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onSearch={onSearch}
            Search={Search}
            onRow={onClickRow}
            ListData={poItems?.list?.map((x, idx) => ({ ...x, key: x.id, index: idx + 1}))}
            onChange={onTableChange}
            ListCol={colName}
            pagination={{
              total: poItems?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </Spin>
  )
}