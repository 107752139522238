import axios from 'Services/axiosInterceptor';
import { externalBaseUrl } from '../../../../configs/constants';

export const addTerminal = (payload) => {
  return axios.post(`${externalBaseUrl}/logins/create`, payload);
};

export const editTerminal = (payload) => {
  return axios.post(`${externalBaseUrl}/logins/update`, payload);
};

export const removeTerminal = (payload) => {
  return axios.post(`${externalBaseUrl}/logins/update-status`, payload);
};

export const bulkRemoveTerminals = (payload) => {
  return axios.post(`${externalBaseUrl}/logins/bulk-update-status`, payload);
};

export const addVoucherType = (payload) => {
  return axios.post(`${externalBaseUrl}/voucher-types/create`, payload);
};

export const editVoucherType = (payload) => {
  return axios.post(`${externalBaseUrl}/voucher-types/update`, payload);
};

export const removeVoucherType = (payload) => {
  return axios.post(`${externalBaseUrl}/voucher-types/update-status`, payload);
};

export const bulkRemoveVoucherTypes = (payload) => {
  return axios.post(`${externalBaseUrl}/voucher-types/bulk-update-status`, payload);
};

export const generateVouchers = (payload) => {
  return axios.post(`${externalBaseUrl}/vouchers/create`, payload);
};

export const removeVoucherItem = (payload) => {
  return axios.post(`${externalBaseUrl}/vouchers/voucher-items-update-status`, payload);
};

export const updateVoucher = (payload) => {
  return axios.post(`${externalBaseUrl}/vouchers/update`, payload);
};

export const removeVoucher = (payload) => {
  return axios.post(`${externalBaseUrl}/vouchers/update-status`, payload);
};

export const addKitchenDisplay = (payload) => {
  return axios.post(`${externalBaseUrl}/kitchen-displays/create`, payload);
};

export const editKitchenDisplay = (payload) => {
  return axios.post(`${externalBaseUrl}/kitchen-displays/update`, payload);
};

export const removeKitchenDisplay = (payload) => {
  return axios.post(`${externalBaseUrl}/kitchen-displays/update-status`, payload);
};

export const bulkRemoveKitchenDisplays = (payload) => {
  return axios.post(`${externalBaseUrl}/kitchen-displays/bulk-update-status`, payload);
};

export const addKitchenQueue = (payload) => {
  return axios.post(`${externalBaseUrl}/kitchen-queues/create`, payload);
};

export const editKitchenQueue = (payload) => {
  return axios.post(`${externalBaseUrl}/kitchen-queues/update`, payload);
};

export const removeKitchenQueue = (payload) => {
  return axios.post(`${externalBaseUrl}/kitchen-queues/update-status`, payload);
};

export const bulkRemoveKitchenQueues = (payload) => {
  return axios.post(`${externalBaseUrl}/kitchen-queues/bulk-update-status`, payload);
};

export const addDiscount = (payload) => {
  return axios.post(`${externalBaseUrl}/discounts/create`, payload);
};

export const editDiscount = (payload) => {
  return axios.post(`${externalBaseUrl}/discounts/update`, payload);
};

export const editDiscountCondition = (payload) => {
  return axios.post(`${externalBaseUrl}/discounts/update-usage-condition`, payload);
};

export const removeDiscount = (payload) => {
  return axios.post(`${externalBaseUrl}/discounts/update-status`, payload);
};

export const bulkRemoveDiscounts = (payload) => {
  return axios.post(`${externalBaseUrl}/discounts/bulk-update-status`, payload);
};

export const addDiscountAvailability = (payload) => {
  return axios.post(`${externalBaseUrl}/discounts/discount-availability-add`, payload);
};

export const editDiscountAvailability = (payload) => {
  return axios.post(`${externalBaseUrl}/discounts/discount-availability-update`, payload);
};

export const removeDiscountAvailability = (payload) => {
  return axios.post(`${externalBaseUrl}/discounts/discount-availability-update-status`, payload);
};

export const bulkRemoveDiscountAvailabilities = (payload) => {
  return axios.post(`${externalBaseUrl}/discounts/discount-availability-bulk-update-status`, payload);
};

export const updateDiscountProductList = (payload) => {
  return axios.post(`${externalBaseUrl}/discounts/discount-products-update`, payload);
};

export const updateDiscountsOrder = (payload) => {
  return axios.post(`${externalBaseUrl}/discounts/update-order`, payload);
};

export const editPosTerminalPassword = (payload) => {
  return axios.post(`${externalBaseUrl}/logins/update-login-password`, payload);
};

export const logoutUser = (payload) => {
  return axios.post(`${externalBaseUrl}/logins/logout`, payload);
};

export const addPrinter = (payload) => {
  return axios.post(`${externalBaseUrl}/printers/create`, payload);
};

export const editPrinter = (payload) => {
  return axios.post(`${externalBaseUrl}/printers/update`, payload);
};

export const removePrinter = (payload) => {
  return axios.post(`${externalBaseUrl}/printers/update-status`, payload);
};

export const bulkRemovePrinters = (payload) => {
  return axios.post(`${externalBaseUrl}/printers/bulk-update-status`, payload);
};

export const updatePrintersOrder = (payload) => {
  return axios.post(`${externalBaseUrl}/printers/update-order`, payload);
};

export const getReport = (payload) => {
  return axios.post(`${externalBaseUrl}/logins/generate-report`, payload);
};

export const exportReportPdf = (payload) => {
  return axios.get(`${externalBaseUrl}/logins/export-report-pdf?${payload}`, { responseType: "blob" });
};

export const importVoucherItems = async (voucherId, file) => {
  if (localStorage.getItem("uploading")) {
    return
  } else {
    localStorage.setItem("uploading", true);
  }
  let paylod = new FormData();
  paylod.append("file", file);
  paylod.append("voucher_id", voucherId);

  let upurl = `${externalBaseUrl}/vouchers/voucher-items-import`;
  try {
    const resp = await axios({ method: 'post', url: upurl, data: paylod, headers: { "Content-Type": "multipart/form-data" } });
    localStorage.removeItem("uploading");
    return resp;
  } catch (e) {
    localStorage.removeItem("uploading");
    return { res: false, msg: e };
  }
};