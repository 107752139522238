export const OUTLET_LIST = 'OUTLET_LIST';
export const OUTLET_OPTION = 'OUTLET_OPTION';
export const OUTLET_DETAIL = 'OUTLET_DETAIL';
export const OUTLET_GROUP_LIST = 'OUTLET_GROUP_LIST';
export const OUTLET_GROUP_DETAIL = 'OUTLET_GROUP_DETAIL';
export const COUNTRY = 'COUNTRY';
export const MENU_STAT = 'MENU_STAT';
export const OUTLET_POS_USER_LIST = 'OUTLET_POS_USER_LIST';
export const DESTROY_SESSION = 'DESTROY_SESSION';
export const BLOCKED_ORDER_TYPE_LIST = 'BLOCKED_ORDER_TYPE_LIST';
export const BLOCKED_PRODUCT_LIST = 'BLOCKED_PRODUCT_LIST';
export const OUTLET_UPLOAD_FILES = 'OUTLET_UPLOAD_FILES';
export const FA_SYNC_LOG_LIST = 'FA_SYNC_LOG_LIST';
