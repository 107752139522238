import React, { useEffect, useState } from 'react';
import { Row, Col, message, Spin, Form, Space, Typography, Button } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUploadFiles } from '../ducks/actions'
import Search from './components/Search';
import { resendUploadFile } from '../ducks/services';
import dayjs from 'dayjs';
// import AllRoles from '../../../routing/config/AllRoles';
// import { allowed } from '../../../routing/config/utils';

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uploadFileList = useSelector((state) => state.outlet.uploadFileList);
  const uploadFileListMeta = useSelector((state) => state.outlet.uploadFileListMeta);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [searchVal, setSearchVal] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const handleResendUploadFile = async (id) => {
    props.setLoading(true);

    const payload = {
      upload_file_id: id
    }
    await resendUploadFile(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Resend successfully");
        dispatch(getUploadFiles(page, limit, '', '', searchVal, props.setLoading));
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  }

  const colName = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: true
    },
    {
      title: 'Outlet',
      dataIndex: 'location',
      key: 'location',
      sorter: true,
      render: (text, record) => <Button type="link" onClick={() => navigate(`/outlets/${record.location_id}`)}>{text}</Button>
    },
    {
      title: 'Filename',
      dataIndex: 'filename',
      key: 'filename',
      sorter: true,
      render: (text, record) => <a href={record.url} target='_blank'>{text}</a>
    },
    {
      title: 'Status (FTP)',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text) => <span className={text === "Success" ? "c-success" : "c-danger"}>{text}</span>
    },
    {
      title: 'Action',
      dataIndex: 'resend',
      key: 'resend',
      sorter: false,
      render: (text, record) => <Button htmlType="button" type='primary' className="green-btn" onClick={() => handleResendUploadFile(record.id)}>Resend</Button>
    }
  ];

  useEffect(() => {
    dispatch(getUploadFiles(1, limit, '', '', null, props.setLoading));
  }, []);

  useEffect(() => {
    if (uploadFileListMeta) {
      if ('locations' in uploadFileListMeta) {
        let temp = [];
        uploadFileListMeta?.locations?.map((item) => {
          temp.push({
            label: `${item.code} ${item.desc}`,
            value: item.id
          })
        });
        setOutlets(temp);
      }

      if ("statuses" in uploadFileListMeta) {
        let temp2 = [];
        uploadFileListMeta.statuses?.map((x, ind) => {
          if (ind === 0) {
            temp2.push({
              label: "All Status",
              value: ""
            });
          }
          temp2.push({
            label: x,
            value: x
          })
        });
        setStatuses(temp2);
      }
    }
  }, [uploadFileListMeta])

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getUploadFiles(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
    } else {
      dispatch(getUploadFiles(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        outlet: search?.outlet?.value,
        status: search?.status?.value,
        date: search?.date ? dayjs(search?.date).format("YYYY-MM-DD") : "null"
      };
      setSearchVal(searching);
      dispatch(getUploadFiles(1, limit, '', '', searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getUploadFiles(1, limit, '', '', null, props.setLoading));
    }
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Mall Sales"} />
        </Col>
        <Col span={24}>
          <ListCard
            Search={Search}
            onSearch={onSearch}
            ListData={uploadFileList?.list?.map(x => ({ ...x, key: x.id }))}
            onChange={onTableChange}
            ListCol={colName}
            field1={outlets}
            field2={statuses}
            pagination={{
              total: uploadFileList?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
    </>
  )
}