import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Typography, Empty, message } from 'antd';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getBillSummary } from '../ducks/actions'
import { exportSalesReport } from '../ducks/services'
import Search from './components/Search';
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { comma } from 'Features/utility';

const { Title, Text } = Typography;
const _ = require('lodash');

export default (props) => {
  var cols = [
    {
      title: 'Business Date',
      dataIndex: 'date',
      key: 'date',
      width: "150px"
    },
    {
      title: 'Outlet',
      dataIndex: 'location_code',
      key: 'location_code',
      width: "150px"
    },
    {
      title: 'Bill No.',
      dataIndex: 'bill_no',
      key: 'bill_no',
      width: "150px"
    },
    {
      title: 'Trans. Count',
      dataIndex: 'trans_count',
      key: 'trans_count',
      align: 'right',
      width: "150px",
      comma: true
    },
    {
      title: 'Guest Count',
      dataIndex: 'guest_count',
      key: 'guest_count',
      align: 'right',
      width: "150px",
      comma: true
    },
    {
      title: 'Gross Amount',
      dataIndex: 'gross_amount',
      key: 'gross_amount',
      align: 'right',
      width: "150px",
      comma: true
    },
    {
      title: 'Discount',
      dataIndex: 'discount_amount',
      key: 'discount_amount',
      align: 'right',
      comma: true
    },
    {
      title: 'Net Sales',
      dataIndex: 'net_sales',
      key: 'net_sales',
      align: 'right',
      width: "150px",
      comma: true
    },
    {
      title: 'Tax',
      dataIndex: 'tax_amount',
      key: 'tax_amount',
      align: 'right',
      comma: true
    },
    {
      title: 'Inclusive Tax Amount',
      dataIndex: 'inclusive_tax_amount',
      key: 'inclusive_tax_amount',
      align: 'right',
      width: "150px",
      comma: true
    },
    {
      title: 'Exclusive Tax Amount',
      dataIndex: 'exclusive_tax_amount',
      key: 'exclusive_tax_amount',
      align: 'right',
      width: "150px",
      comma: true
    },
    {
      title: 'Voucher',
      dataIndex: 'voucher_amount',
      key: 'voucher_amount',
      align: 'right',
      comma: true
    },
    {
      title: 'Charges',
      dataIndex: 'delivery_charge',
      key: 'delivery_charge',
      align: 'right',
      comma: true
    },
    {
      title: 'Adj. Amount',
      dataIndex: 'adjustment_amount',
      key: 'adjustment_amount',
      align: 'right',
      width: "150px",
      comma: true
    },
    {
      title: 'Net Total',
      dataIndex: 'net_total',
      key: 'net_total',
      align: 'right',
      width: 120,
      comma: true
    }
  ];

  const dispatch = useDispatch();
  const orders = useSelector((state) => state.reporting.billSummary);
  const meta = useSelector((state) => state.reporting.billSummaryOption);
  const [searchVal, setSearchVal] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const [colName, setColName] = useState(cols);

  const btnList = [
    {
      text: 'Export Report',
      classes: 'green-btn',
      action: () => exportReport(),
    }
  ];

  useEffect(() => {
    dispatch(getBillSummary(searchVal, props.setLoading, "1"));
    dispatch(getListQueryItems('setupListQuery', null));
  }, []);

  useEffect(() => {
    if (meta) {
      if ("locations" in meta) {
        let temp = [];
        meta.locations?.map((x, ind) => {
          temp.push({
            label: `${x.code} - ${x.desc}`,
            value: x.id
          })
        });
        setOutlets(temp);
      }
    }
    if (orders?.headers) {
      let temp = cols;
      orders?.fe_cols?.map(x => {
        temp.push({
          title: x.label,
          dataIndex: x.key,
          key: x.key,
          align: 'right',
          width: "120px",
          comma: true
        })
      });

      setColName(temp);
    }
  }, [meta, orders]);

  const onSearch = (search) => {
    if (search && 'outlets' in search) {
      let searching = {};
      searching = {
        is_non_sales: search?.is_non_sales?.value,
        date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : "",
        date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : "",
      };
      if (search?.outlets?.length > 0) {
        let temp2 = [];
        search?.outlets?.map(x => {
          temp2.push(x.value)
        });
        searching["location_ids"] = JSON.stringify(temp2);
      }
      setSearchVal(searching);
      dispatch(getBillSummary(searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getBillSummary(null, props.setLoading));
    }
  };

  const exportReport = async () => {
    if (orders?.total_count === 0) {
      return message.warning("No data to be exported.");
    }
    props.setLoading(true);

    await exportSalesReport(searchVal, "bill_summary").then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? 'Something went wrong');
    })
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Bill Summary Report"} btnList={allowed([AllRoles.REPORTING.SALES_PAYMENT], 'write') ? btnList : null} />
        </Col>
        <Col span={24} className="">
          <Card bordered={false}>
            <Search onSearch={onSearch} field1={outlets} />

            <div className="table-responsive" style={{ overflowX: 'auto', marginTop: "30px" }}>
              <table className='table table-borderless w-100' style={{ borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    {
                      colName.map((x) => (
                        <th key={x.key} className={x.align == "right" ? "text-right" : ""} style={{minWidth: x.width && x.width}}>{x.title}</th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    orders?.list?.length <= 0 &&
                    <tr>
                      <td colSpan="14">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  }
                  {
                    orders?.list?.length > 0 &&
                    orders?.list?.map((x, ind) => {
                      return (
                        <Fragment key={ind}>
                          {
                            x.list?.map((y, yIndex) => {
                              return (
                                <tr key={yIndex}>
                                  {
                                    colName.map((x) => (
                                      <td key={x.key} align={x.align} style={{ minWidth: x.width && x.width }}>
                                        {y.comma ? comma(y[x.dataIndex]) : y[x.dataIndex]}
                                      </td>
                                    ))
                                  }
                                </tr>
                              )
                            })
                          }
                          <tr className='bg-gray text-white fwbold'>
                            <td align='' colSpan="3">SubTotal</td>
                            {
                              x?.sub_totals && Object.entries(x?.sub_totals)?.map(([key, data], zIndex) => {
                                return (
                                  <td key={zIndex} align='right'>{comma(data)}</td>
                                )
                              })
                            }
                          </tr>
                        </Fragment>
                      )
                    })
                  }
                  <tr className='bg-gray fwbold text-white'>
                    {
                      orders?.list?.length <= 0
                        ? <td colSpan="99">Grand Total</td>
                        : <td align='' colSpan="3">Grand Total</td>
                    }
                    {
                      orders?.grand_totals && Object.entries(orders?.grand_totals)?.map(([key, data], index) => {
                        return (
                          <td key={index} align='right'>
                            {comma(data)}
                          </td>
                        )
                      })
                    }
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}