import React, { useEffect, useState } from 'react';
import { Row, Col, Button, message, Space, Popconfirm } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getVouchers } from '../ducks/actions'
import { removeVoucher, bulkRemoveVouchers } from '../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import Search from './components/Search'
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vouchers = useSelector((state) => state.pos.vouchers);
  const meta = useSelector((state) => state.pos.voucherOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [searchVal, setSearchVal] = useState(null);
  const [voucherTypes, setVoucherTypes] = useState(null);
  const allowedEdit = allowed([AllRoles.POS.VOUCHERS], 'write');
  const allowedDelete = allowed([AllRoles.POS.VOUCHERS], 'delete');
  const globalListQuery = useSelector((state) => state.global.listQueryItems);

  const colName = [
    // {
    //   title: 'Code',
    //   dataIndex: 'code',
    //   key: 'code',
    //   className: 'enable-click',
    //   sorter: true
    // },
    {
      title: 'Voucher Code',
      dataIndex: 'code',
      key: 'code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Voucher Type',
      dataIndex: 'voucher_type_desc',
      key: 'voucher_type_desc',
      className: 'enable-click',
      sorter: true,
      render: (text, record) => <span>{record.voucher_type_code} {`(${text})`}</span>
    },
    {
      title: 'Is User Borne?',
      dataIndex: 'is_user_borne',
      key: 'is_user_borne',
      className: 'enable-click',
      sorter: true,
      align: 'center',
      render: (text) => <span>{text === "1" ? <CheckCircleFilled className='c-success' style={{ fontSize: '20px' }} /> : <CloseCircleFilled className='c-danger' style={{ fontSize: '20px' }} />}</span>
    },
    {
      title: 'Effective Date',
      dataIndex: 'from',
      key: 'from',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Expiry Date',
      dataIndex: 'to',
      key: 'to',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Voucher Value',
      dataIndex: 'amount',
      key: 'amount',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Active" ? "c-success" : "c-danger"}>
            {text}
          </span>
          {
            ["Active", "Inactive"].includes(text) && allowedEdit &&
            <Button type="link" onClick={() => onAction(record.id, text)}>
              {text === "Active" ? "(Deactivate)" : "(Activate)"}
            </Button>
          }
        </div>
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="Voucher"
          btnAction1={() => navigate(`/pos/vouchers/${record.id}`)}
          recordId={record.id}
          onRemove={record.is_deletable === "1" && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ]

  const btnList = [
  ];

  useEffect(() => {
    if(!globalListQuery.vouchersListQuery) {
      dispatch(getVouchers(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('vouchersListQuery', 1, limit, '', '', null, props.setLoading));
    } else {
      const {page, limit, sortOrder, sortColKey, searchValue} = globalListQuery.vouchersListQuery
      dispatch(getVouchers(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
      setPage(page);
      setLimit(limit);
      setSearchVal(searchValue);
    }
  }, []);

  useEffect(() => {
    if(meta) {
      if('voucher_types' in meta) {
        let temp = []
        meta.voucher_types.map((x, ind) => {
          if(ind === 0) {
            temp.push({
              label: 'All Voucher Types',
              value: ''
            });
          }
          temp.push({
            label: `${x.code} (${x.desc})`,
            value: x.id
          })
        });
        setVoucherTypes(temp);
      }
    }
  }, [meta]);

  const onAction = async (vId, status) => {
    props.setLoading(true);

    const payload = {
      voucher_id: vId,
      status_event: status === 'Active' ? 'deactivate' : 'activate'
    }

    await removeVoucher(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Voucher status has been updated successfully!");
        setTimeout(() => dispatch(getVouchers(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('vouchersListQuery', page, limit, '', '', searchVal, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onRemove = async (id) => {
    props.setLoading(true);

    const payload = {
      voucher_id: id,
      status_event: 'remove'
    }

    await removeVoucher(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Voucher has been removed successfully!");
        setTimeout(() => dispatch(getVouchers(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('vouchersListQuery', page, limit, '', '', searchVal, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getVouchers(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
      dispatch(getListQueryItems('vouchersListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading))
    } else {
      dispatch(getVouchers(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
      dispatch(getListQueryItems('vouchersListQuery', pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading))
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        name: search.code,
        voucher_type: search.voucher_type
      };
      setSearchVal(searching);
      dispatch(getVouchers(1, limit, '', '', searching, props.setLoading));
      dispatch(getListQueryItems('vouchersListQuery', 1, limit, '', '', searching, props.setLoading))
    } else {
      setSearchVal(null);
      dispatch(getVouchers(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('vouchersListQuery', 1, limit, '', '', null, props.setLoading))
    }
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click"))
          navigate(`/pos/vouchers/${record.id}`)
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Vouchers"} btnList={allowedEdit ? btnList : null} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            Search={Search}
            onSearch={onSearch}
            searchVal={globalListQuery.vouchersListQuery?.searchValue}
            onRow={onClickRow}
            ListData={vouchers?.list?.map(x => ({ ...x, key: x.id }))}
            ListCol={colName}
            onChange={onTableChange}
            field1={voucherTypes}
            pagination={{
              total: vouchers?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
    </>
  )
}