import React, { useState } from 'react';
import Dashboard from '../../templates/Dashboard';
import StockBalance from 'Modules/InventoryReporting/StockBalance';
import Inventory from 'Modules/InventoryReporting/Inventory';
import NetsuiteSync from 'Modules/InventoryReporting/NetsuiteSync';

const Components = {
  StockBalance,
  Inventory,
  NetsuiteSync
};

export default (props) => {
  const InvReportComp = Components[props.Comp];
  const [loading, setLoading] = useState(false);

  return (
    <Dashboard load={loading} setLoading={setLoading}>
      <InvReportComp loading={loading} setLoading={setLoading} />
    </Dashboard>
  );
};