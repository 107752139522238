import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Typography, Table, message } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getSalesVoucherListing } from '../ducks/actions'
import { exportSalesReport } from '../ducks/services'
import Search from './components/Search';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { comma } from 'Features/utility';
import dayjs from 'dayjs';

const { Title, Text } = Typography;
const _ = require('lodash');

export default (props) => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.reporting.salesVoucherListing);
  const meta = useSelector((state) => state.reporting.salesVoucherListingOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(250);
  const [searchVal, setSearchVal] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [voucherTypes, setVoucherTypes] = useState([]);

  const colName = [
    {
      title: 'Voucher No.',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Voucher Value',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Used Date',
      dataIndex: 'used_date',
    },
    {
      title: 'Used by Outlet',
      dataIndex: 'outlet',
    },
    {
      title: 'Used by Bill No.',
      dataIndex: 'sales_order',
      key: 'sales_order',
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
      align: 'right'
    }
  ];

  const btnList = [
    {
      text: 'Export Report',
      classes: 'green-btn',
      action: () => exportReport(),
    }
  ];

  useEffect(() => {
    dispatch(getSalesVoucherListing(1, limit, searchVal, props.setLoading, "1"));
    dispatch(getListQueryItems('setupListQuery', null));
  }, []);

  useEffect(() => {
    if (meta) {
      if ("statuses" in meta) {
        let temp = [];
        meta.statuses?.map((x, ind) => {
          if(ind === 0) {
            temp.push({
              label: "All",
              value: null
            })
          }
          temp.push({
            label: x,
            value: x
          })
        });
        setStatuses(temp);
      }
      if ("voucher_types" in meta) {
        let temp = [];
        meta.voucher_types?.map((x, ind) => {
          temp.push({
            label: x.counter_reference,
            value: x.id
          })
        });
        setVoucherTypes(temp);
      }
    }
  }, [meta]);

  const onSearch = (search) => {
    if (search && 'status' in search) {
      let searching = {};
      searching = {
        status: search?.status?.value,
        voucher_no: search?.voucher_type?.value,
        voucher_type_reference: search?.voucher_type?.label,
        date_from: search.start_date ? dayjs(search.start_date).format("YYYY-MM-DD") : "",
        date_to: search.end_date ? dayjs(search.end_date).format("YYYY-MM-DD") : "",
      };
      setSearchVal(searching);
      dispatch(getSalesVoucherListing(1, limit, searching, props.setLoading, false, message));
    } else {
      setSearchVal(null);
      dispatch(getSalesVoucherListing(1, limit, null, props.setLoading, false, message));
    }
  };

  const exportReport = async () => {
    if (orders?.total_count === 0) {
      return message.warning("No data to be exported.");
    }
    props.setLoading(true);

    await exportSalesReport(searchVal, "sales_voucher_listing").then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? 'Something went wrong');
    })
  };

  const onTableChange = (pagination) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    dispatch(getSalesVoucherListing(pagination.current, pagination.pageSize, searchVal, props.setLoading, false, message));
  }

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Voucher Listing"} btnList={allowed([AllRoles.REPORTING.SALES_PAYMENT], 'write') ? btnList : null} />
        </Col>
        <Col span={24} className="">
          <ListCard
            Search={Search}
            onSearch={onSearch}
            ListData={orders?.list?.map((x, ind) => ({ ...x, key: ind }))}
            onChange={onTableChange}
            ListCol={colName?.filter(x => !x.hidden)}
            field1={statuses}
            field2={voucherTypes}
            summary={() => {
              return (
                <>
                  <Table.Summary.Row className='bg-gray'>
                    <Table.Summary.Cell className='text-white text-bold' index={0} colSpan={5} align='right'>
                      <Text>Grand Total</Text>
                    </Table.Summary.Cell>
                    {
                      orders?.grand_totals && Object.entries(orders?.grand_totals).length > 0 ?
                      Object.entries(orders?.grand_totals)?.map(([key, data], index) => {
                        return (
                          <Table.Summary.Cell key={index} className='text-white text-right text-bold' index={index + 3}>
                            <Text>{comma(data)}</Text>
                          </Table.Summary.Cell>
                        )
                      })
                      :
                      <Table.Summary.Cell className='text-white text-right text-bold' index={4}>
                        <Text>0</Text>
                      </Table.Summary.Cell>
                    }
                  </Table.Summary.Row>
                </>
              )
            }}
            pagination={{
              total: orders?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
    </>
  )
}