import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Typography, Empty, message, Space } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getPosAuditLogListing } from '../ducks/actions'
import { exportSalesReport } from '../ducks/services'
import Search from './components/Search';
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';

const { Title, Text } = Typography;
const _ = require('lodash');

function secToMin(seconds) {
  return seconds / 60;
}

export default (props) => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.reporting.posAuditLogListing);
  const meta = useSelector((state) => state.reporting.posAuditLogListingOption);
  const [searchVal, setSearchVal] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const colName = [
    {
      title: 'Business Date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => <span>{dayjs(text).format("YYYY-MM-DD HH:mm:ss")}</span>
    },
    {
      title: 'Outlet',
      dataIndex: 'location_code',
      key: 'location_code'
    },
    {
      title: 'Report',
      dataIndex: 'desc',
      key: 'desc',
    },
    {
      title: 'Report Keys',
      dataIndex: 'reference',
      key: 'reference',
    }
  ];


  const btnList = [
    {
      text: 'Export Report',
      classes: 'green-btn',
      action: () => exportReport(),
    }
  ];

  useEffect(() => {
    dispatch(getPosAuditLogListing(searchVal, props.setLoading, "1"));
    dispatch(getListQueryItems('setupListQuery', null));
  }, []);

  useEffect(() => {
    if (meta) {
      if ("locations" in meta) {
        let temp = [];
        meta.locations?.map((x, ind) => {
          temp.push({
            label: `${x.code} - ${x.desc}`,
            value: x.id
          })
        });
        setOutlets(temp);
      }
    }
  }, [meta]);

  const onSearch = (search) => {
    if (search && 'outlets' in search) {
      let searching = {};
      searching = {
        date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : "",
        date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : "",
        receipt_no: search?.receipt_no
      };
      if (search?.outlets?.length > 0) {
        let temp2 = [];
        search?.outlets?.map(x => {
          temp2.push(x.value)
        });
        searching["location_ids"] = JSON.stringify(temp2);
      }
      setSearchVal(searching);
      dispatch(getPosAuditLogListing(searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getPosAuditLogListing(null, props.setLoading));
    }
  };

  const exportReport = async () => {
    if (orders?.total_count === 0) {
      return message.warning("No data to be exported.");
    }
    props.setLoading(true);

    await exportSalesReport(searchVal, "pos_audit_log", selectedRowKeys).then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? 'Something went wrong');
    })
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Pos Audit Log Report"} btnList={allowed([AllRoles.REPORTING.SALES_PAYMENT], 'write') ? btnList : null} />
        </Col>
        <Col span={24} className="">
          <ListCard
            Search={Search}
            onSearch={onSearch}
            ListData={orders?.list?.map((x, ind) => ({ ...x, key: `${x.id} ${ind}` }))}
            rowSelection={rowSelection}
            ListCol={colName}
            field1={outlets}
            pagination={false}
          />
        </Col>
      </Row>
    </>
  )
}