import React, { useEffect, Fragment, useState } from 'react';
import { Row, Col, Form, Button, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';

const _ = require('lodash');
const todayDate = new Date();

export default (props) => {
  const { id, addGoodReceive, updateGoodReceive, meta, data, mode, reloadApi, allowedEdit, setCanPost, editable, setEditable } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  // const [editable, setEditable] = useState(true);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [searchParams] = useSearchParams();
  const [invoiceValue, setInvoiceValue] = useState('')

  const disabledDates = (current) => {
    return startDate && current && current < startDate.startOf('day');
  }

  const noSpecificCharAllowed = (e, char, onSetValue) => {
    if(e.slice(-1) === char) onSetValue(e.slice(0,-1))
    else onSetValue(e)
  }

  const formFields = [
    {
      name: 'reference',
      label: 'Invoice / DO No.',
      req: true,
      placeholder: 'Please state',
      type: 'grInvoiceInput',
      twocol: true,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !editable,
      onChange: (e) => noSpecificCharAllowed(e.target.value, "-", setInvoiceValue),
      hasValue: invoiceValue
    },
    {
      name: 'date',
      label: 'Invoice date',
      req: true,
      placeholder: 'Please state',
      type: 'date',
      format: 'YYYY-MM-DD',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !editable,
      disabledDate: disabledDates
    },
    {
      name: 'id',
      label: 'Reference No.',
      placeholder: 'System Generated',
      type: 'grInvoiceInput',
      twocol: false,
      colWidth: '0 1 51%',
      reqmessage: 'Required',
      static: true,
      hasValue: invoiceValue
    },
    {
      type: 'select',
      label: 'Outlet',
      name: 'location',
      twocol: false,
      options: meta?.locations?.map((x) => ({ label: `${x.code} ${x.desc}`, value: x.id })),
      req: true,
      placeholder: 'Please select',
      reqmessage: 'Outlet Required',
      colWidth: '0 1 50%',
      static: !editable
    },
    {
      type: 'select',
      label: 'Vendor',
      name: 'vendor',
      twocol: false,
      options: meta?.vendors?.map((x) => ({ label: `${x.code} ${x.name}`, value: x.id })),
      req: true,
      placeholder: 'Please select',
      reqmessage: 'Vendor Required',
      colWidth: '0 1 50%',
      static: !editable,
      onChange: (e) => {
        let pos = meta?.purchase_orders.filter(x => x.vendor_id === e.value);
        let temp = [];
        pos?.map((x) => {
          temp.push({ ...x, label: x.reference, value: x.id })
        })
        setPurchaseOrders(temp);
        setValue('purchase_order', '');
        setValue('purchase_order_date', '');
      }
    },
    {
      type: 'select',
      label: 'Currency',
      name: 'currency',
      twocol: false,
      options: meta?.currencies?.map((x) => ({ label: x.code, value: x.id })),
      req: true,
      placeholder: 'Please select',
      reqmessage: 'Currency Required',
      colWidth: '0 1 100%',
      static: !editable
    },
    {
      type: 'select',
      label: 'PO No.',
      name: 'purchase_order',
      twocol: false,
      options: purchaseOrders,
      req: true,
      placeholder: 'Please select',
      reqmessage: 'PO No. Required',
      colWidth: '0 1 50%',
      static: !editable,
      isClearable: true,
      onChange: (e) => {
        if(e?.value) {
          setValue('purchase_order_date', e.date ? dayjs(e.date) : '');
        }else{
          setValue('purchase_order_date', '');
        }

        if(e.location_id) {
          let location = meta?.locations?.find((x) => x.id === e.location_id);
          setValue('location', { label: `${location.code} ${location.desc}`, value: location.id });
        }
        
        if(e.currency_id) {
          let currency = meta?.currencies?.find((x) => x.id === e.currency_id);
          setValue('currency', {label: currency.code, value: currency.id});
        }
      }
    },
    {
      name: 'purchase_order_date',
      label: 'PO date',
      req: false,
      static: true,
      placeholder: 'Please state',
      type: 'date',
      format: 'YYYY-MM-DD',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required'
    },
    // {
    //   type: 'select',
    //   label: 'Payment Term',
    //   name: 'payment_term',
    //   twocol: false,
    //   options: meta?.payment_terms?.map((x) => ({ label: x.code, value: x.id })),
    //   req: false,
    //   placeholder: 'Please select',
    //   reqmessage: 'Payment Term Required',
    //   colWidth: '0 1 50%',
    //   isClearable: true,
    //   static: !editable
    // },
    // {
    //   type: 'select',
    //   label: 'Tax Condition',
    //   name: 'tax_condition',
    //   twocol: false,
    //   options: meta?.tax_conditions?.map((x) => ({ label: x.code, value: x.id })),
    //   req: false,
    //   placeholder: 'Please select',
    //   reqmessage: 'Tax Condition Required',
    //   colWidth: '0 1 50%',
    //   isClearable: true,
    //   static: !editable
    // },
    {
      name: 'remarks',
      label: 'Remarks',
      placeholder: 'Please enter',
      type: 'textarea',
      twocol: false,
      colWidth: '0 1 100%',
      static: !editable
    },
    {
      name: 'cancellation_remarks',
      label: 'Cancellation Remarks',
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      static: !editable,
      hidden: data && data?.cancellation_remarks ? false : true
    },
  ];

  useEffect(() => {
    if(!data || !Object.keys(data).length) {
      setValue('date', dayjs(todayDate))
    }
    if (data && 'id' in data) {
      setValue('id', data.id);
      setValue('reference', data.reference);
      setInvoiceValue(data.reference)
      setValue('remarks', data.remarks);
      setValue('date', data.date ? dayjs(data.date) : '');
      setValue('location', data.location_id ? { label: `${data.location_code} ${data.location_desc}`, value: data.location_id } : '');
      setValue('vendor', data.vendor_id ? { label: `${data.vendor_code} ${data.vendor_desc}`, value: data.vendor_id } : '');
      setValue('currency', data.currency_id ? { label: data.currency_code, value: data.currency_id } : '');
      setValue('purchase_order', data.source_id ? { label: data.source_desc, value: data.source_id} : '');
      setValue('purchase_order_date', data.source_date ? dayjs(data.source_date) : '');
      setValue('payment_term', data.payment_term_id ? { label: data.payment_term_code, value: data.payment_term_id } : '');
      setValue('tax_condition', data.tax_condition_id ? { label: data.tax_condition_code, value: data.tax_condition_id } : '');
      setValue('cancellation_remarks', data?.cancellation_remarks);
      if(meta && data?.vendor_id) {
        let pos = meta?.purchase_orders.filter(x => x.vendor_id === data.vendor_id);
        let temp = [];
        pos?.map((x) => {
          temp.push({ ...x, label: x.reference, value: x.id })
        })
        setPurchaseOrders(temp);
      }
      setEditable(data?.status === "pending" && allowedEdit);

      if(data.reference && data.currency_id) {
        setCanPost(true);
      }else{
        setCanPost(false);
      }
    }

    setStartDate(dayjs(meta?.start_date));
  }, [data, meta]);

  const onFinish = async (val) => {
    if (mode === 'add') {
      return onFinishAdd(val);
    }
    props.setLoading(true);
    let payload = {
      reference: val?.reference,
      date: val?.date ? dayjs(val?.date).format("YYYY-MM-DD") : '',
      location_id: val?.location?.value,
      vendor_id: val?.vendor?.value,
      currency_id: val?.currency?.value,
      payment_term_id: val?.payment_term?.value || "",
      tax_condition_id: val?.tax_condition?.value || "",
      remarks: val?.remarks,
      stock_in_id: id
    }

    if (val?.purchase_order?.value) {
      payload["source_id"] = val?.purchase_order?.value
      payload["source_type"] = 'PurchaseOrder'
    }else{
      payload["source_id"] = ""
    }

    await updateGoodReceive(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Updated Good Receive successfully!");
        reloadApi();
        if(searchParams.get('isRedirected') === 'true') {
          setTimeout(() => window.location.href = `/inventory/good-receive/${id}?t=2`, 500)
        }
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  const onFinishAdd = async (val) => {
    props.setLoading(true);

    let payload = {
      reference: val?.reference,
      date: val?.date ? dayjs(val?.date).format("YYYY-MM-DD") : '',
      location_id: val?.location?.value,
      vendor_id: val?.vendor?.value,
      currency_id: val?.currency?.value,
      payment_term_id: val?.payment_term?.value,
      tax_condition_id: val?.tax_condition?.value,
      remarks: val?.remarks,
    }
    if(val?.purchase_order?.value){
      payload["source_id"] = val?.purchase_order?.value
      payload["source_type"] = 'PurchaseOrder'
    }else{
      payload["source_id"] = ""
    }
    await addGoodReceive(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Good Receive created successfully!");
        setTimeout(() => {
          reloadApi(res.data.data)
        }, 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)} id="gr-details-form">
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {/* {editable &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn text-white'>Save</Button>
          </Col>
        } */}
      </Row>
    </Form>
  )
}