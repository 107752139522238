import React, { Fragment } from 'react';

export default (props) => {
  const { columns, data } = props;

  return (
    <div className="inventory-table-container">
      <div className="inventory-table">
        <table>
          <thead>
            <tr>
              {columns?.adj_details_listing?.list?.map(item =>
                <th className={`${item?.longCol ? 'long-col' : ''} text-${item.align}`} key={`Title ${item.title}`}>
                  {item.title}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data?.list?.map((listItem, listItemIndex) => 
              <Fragment key={`List Item ${listItemIndex}`}>
                {listItem?.list?.map((childListItem, childListItemIndex) => 
                  <Fragment key={`List Item ${listItemIndex}: Child List Item ${childListItemIndex}`}>
                    {childListItem?.list?.map((grandChildListItem, grandChildListItemIndex) => 
                      <tr key={`List Item ${listItemIndex}: Child List Item ${childListItemIndex} Grand List Item ${grandChildListItemIndex}`}>
                        <td align='left'>{childListItem.date}</td>
                        <td>{childListItem.location}</td>
                        <td align='left'>{childListItem.reference}</td>
                        <td align='left'>{grandChildListItem.item}</td>
                        <td align='left'>{grandChildListItem.uom}</td>
                        <td align='right'>{grandChildListItem.qty}</td>
                        <td align='right'>{grandChildListItem.unit_cost}</td>
                        <td align='right'>{grandChildListItem.inv_cost}</td>
                      </tr>
                    )}
                  </Fragment>
                )}
                <tr className='bg-gray text-white'>
                  <td colSpan={3}></td>
                  <td>Subtotal</td>
                  {listItem?.sub_totals?.map((item, index) => 
                    <td key={`Sub Total ${item} ${index}`} align='right'>{item}</td>
                  )}
                </tr>
              </Fragment>
            )}

            
            <tr className='bg-gray text-white'>
              <td colSpan={3}></td>
              <td>Grand Total</td>
              {data?.grand_total?.map((item, index) => 
                <td key={`Grand Total ${item} ${index}`} align='right'>{item}</td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}