import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Empty } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import FormGroup from 'Molecules/FormGroup';
import { useDispatch, useSelector } from 'react-redux';
import { getGrItemList } from '../../../../../ducks/actions'
import { editGrItem, addGrItem, destroyGrItem } from '../../../../../ducks/services'
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import Search from './Search'
import ActionButton from 'Molecules/ActionButton';
import AllRoles from '../../../../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../../../../routing/config/utils';

const _ = require('lodash');

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, activeTab, data, allowedEdit, allowedDelete, reloadApi, setNoZeroReceiveQty } = props;
  const { control, formState: { errors }, handleSubmit, setValue, clearErrors } = useForm();
  const { control: control2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, setValue: setValue2, clearErrors: clearErrors2 } = useForm();
  const grItems = useSelector((state) => state.inventory.grItems);
  const meta = useSelector((state) => state.inventory.grItemOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [load, setLoad] = useState(false);
  const [visible, setVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [searchVal, setSearchVal] = useState();
  const editable = data?.status === "pending" && allowedEdit;

  const colName = [
    {
      title: 'No.',
      dataIndex: 'index',
      key: 'index',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'Item',
      dataIndex: 'source_name',
      key: 'source_name',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'Net Unit Amount',
      dataIndex: 'price',
      key: 'price',
      align: 'right',
      sorter: true,
      className: 'enable-click',
      hidden: !allowed([AllRoles.INVENTORY.GOOD_RECEIVE], 'read')
    },
    {
      title: 'UOM Code',
      dataIndex: 'uom_code',
      key: 'uom_code',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'System Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: true,
      className: 'enable-click',
      align: 'right',
      render: (text) => <span>{parseFloat(text).toFixed(5)}</span>
    },
    {
      title: 'Received Qty.',
      dataIndex: 'actual_quantity',
      key: 'actual_quantity',
      sorter: true,
      className: 'enable-click',
      align: 'right',
      render: (text) => <span>{parseFloat(text).toFixed(5)}</span>
    },
    {
      title: 'Gross Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: true,
      align: 'right',
      className: 'enable-click',
      hidden: !allowed([AllRoles.INVENTORY.GOOD_RECEIVE], 'read')
    },
    {
      title: 'Net Amount',
      dataIndex: 'net_amount',
      key: 'net_amount',
      sorter: true,
      align: 'right',
      className: 'enable-click',
      hidden: !allowed([AllRoles.INVENTORY.GOOD_RECEIVE], 'read')
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="GR Item"
          btnAction1={editable ? () => {
            setEditVisible(true);
            setValue2('id', record.id);
            setValue2('actual_quantity', record.actual_quantity);
            setValue2('quantity_threshold', record.quantity_threshold);
            setValue2('remarks', record.remarks);
          } : false}
          recordId={record.id}
          onRemove={(data?.status === "pending" && allowedDelete) && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const formFields = [
    {
      name: 'item',
      label: 'Item',
      req: visible,
      placeholder: 'Please select',
      type: 'select',
      class: 'default-select',
      options: _.map(meta?.vendor_products, (e) => ({ label: e.product_code, value: e.product_id, uom_id: e.uom_id, uom_code: e.uom_code, price: e.price })),
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      onChange: (e) => {
        setValue('uom', e.uom_id);
        setValue('price', e.price);
      }
    },
    {
      name: 'uom',
      label: 'UOM',
      placeholder: 'Please state',
      type: 'input',
      number: true,
      hidden: true
    },
    {
      name: 'price',
      label: 'Price',
      placeholder: 'Please state',
      type: 'input',
      number: true,
      hidden: true
    },
    {
      name: 'quantity',
      label: 'Quantity',
      req: visible,
      placeholder: 'Please select',
      type: 'input',
      number: true,
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
    }
  ];

  const formFields2 = [
    {
      name: 'id',
      label: '',
      type: 'input',
      hidden: true,
    },
    {
      name: 'quantity_threshold',
      label: '',
      type: 'input',
      hidden: true,
    },
    {
      name: 'actual_quantity',
      label: 'Received Qty.',
      req: editVisible,
      placeholder: 'Please enter',
      type: 'input',
      number: true,
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
    },
    {
      name: 'remarks',
      label: 'Remarks',
      req: false,
      placeholder: 'Please enter',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
    },
  ]

  useEffect(() => {
    // if (activeTab)
      dispatch(getGrItemList(1, limit, '', '', null, props.setLoading, id));
  }, []);

  useEffect(() => {
    if(grItems.list?.every((item) => item.actual_quantity > 0)) {
      setNoZeroReceiveQty(true)
    } else {
      setNoZeroReceiveQty(false)
    }
  }, [grItems])

  const addNew = () => {
    setVisible(true);
    setValue('id', '');
    setValue('item', '');
    setValue('uom', '');
    setValue('price', '');
    setValue('quantity', '');
    clearErrors();
  }

  const btnList = [
    {
      text: '+ New GR Item',
      classes: 'attendance-success',
      action: () => addNew(),
      btnHidden: !!data?.source_id
    }
  ];

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getGrItemList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading, id));
    } else {
      dispatch(getGrItemList(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading, id));
    }
  }
  
  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        product_code: search?.product_code
      };
      setSearchVal(searching);
      dispatch(getGrItemList(1, limit, '', '', searching, props.setLoading, id));
    } else {
      setSearchVal(null);
      dispatch(getGrItemList(1, limit, '', '', null, props.setLoading, id));
    }
  };

  const onFinish = async (val) => {
    setLoad(true);

    const payload = {
      uom_id: val?.uom,
      quantity: val?.quantity,
      price: val?.price,
      product_id: val?.item?.value,
      stock_in_id: id
    }
    await addGrItem(payload).then(res => {
      setLoad(false);
      if(res.data.code === 200) {
        message.success("GR Item Added Successfully!");
        setVisible(false);
        setPage(1);
        reloadApi();
        setTimeout(() => dispatch(getGrItemList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      }else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  const onFinishEdit = async (val) => {
    if (parseFloat(val?.actual_quantity) > parseFloat(val?.quantity_threshold)) {
      if (data?.is_blocked_stock_in_exceed_threshold === "1") {
        message.error("Received Quantity cannot be greater than System Quantity.");
        return;
      }else {
        if (confirm("Unusual Quantity Detected, Are you sure to proceed?")) {
        } else {
          return;
        }
      }
    }

    setLoad(true);

    const payload = {
      remarks: val?.remarks,
      actual_quantity: val?.actual_quantity,
      stock_in_item_id: val?.id,
      stock_in_id: id
    }
    await editGrItem(payload).then(res => {
      setLoad(false);
      if(res.data.code === 200) {
        message.success(`GR Item Updated Successfully!`);
        setEditVisible(false);
        setPage(1);
        setTimeout(() => dispatch(getGrItemList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      }else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  const onRemove = async (recId) => {
    props.setLoading(true);

    const payload = {
      stock_in_id: id,
      stock_in_item_id: recId
    }

    await destroyGrItem(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("GR Item has been removed successfully!");
        setPage(1);
        reloadApi();
        setTimeout(() => dispatch(getGrItemList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            Add GR Item
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('id', '');
              setValue('item', '');
              setValue('uom', '');
              setValue('price', '');
              setValue('quantity', '');
              clearErrors();
            }}>Cancel</Button></Col>
            {
              editable &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setValue('id', '');
      setValue('item', '');
      setValue('uom', '');
      setValue('price', '');
      setValue('quantity', '');
      clearErrors();
    },
  };

  const popup2 = {
    closable: false,
    visibility: editVisible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit2(onFinishEdit)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            Edit GR Item
          </Title>
          <Row gutter={[10, 10]}>
            {formFields2.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control2} errors={errors2} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setEditVisible(false);
              setValue2('id', '');
              setValue2('actual_quantity', '');
              setValue2('remarks', '');
              clearErrors2();
            }}>Cancel</Button></Col>
            {
             editable &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col> 
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setEditVisible(false);
      setValue2('id', '');
      setValue2('actual_quantity', '');
      setValue2('remarks', '');
      clearErrors2();
    },
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click") && editable){
          clearErrors2();
          setValue2('id', record.id);
          setValue2('actual_quantity', record.actual_quantity);
          setValue2('quantity_threshold', record.quantity_threshold);
          setValue2('remarks', record.remarks);
          setEditVisible(true);
        }
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={''} btnList={editable ? btnList : []} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onSearch={onSearch}
            Search={Search}
            onRow={onClickRow}
            ListData={grItems?.list?.map((x, idx) => ({ ...x, key: x.id, index: idx + 1}))}
            onChange={onTableChange}
            ListCol={colName.filter(x => !x.hidden)}
            pagination={{
              total: grItems?.total_count,
              current: page,
              pageSize: limit
            }}
            summary={() => <tr>
              <td colSpan={6}></td>
              <td className='text-right fwbold'>Total:</td>
              <td className='text-right'>{grItems?.total_net_amount}</td> 
              </tr>
            }
          />
        </Col>
      </Row>
      <Popup {...popup} />
      <Popup {...popup2} />
    </>
  )
}