import * as action_types from './constants';

const initialState = {
  stockBalances: {},
  option: {},
  inventoryReport: {},
  inventoryReportMeta: {},
  netsuiteReport: {},
  netsuiteReportMeta: {},
};

export default (state = initialState, action) => {
  const { type, data, meta } = action;
  switch (type) {
    case action_types.REPORTING_STOCK_BALANCE:
      return { ...state, stockBalances: data, option: meta };
    case action_types.REPORTING_INVENTORY:
      return { ...state, inventoryReport: data };
    case action_types.REPORTING_INVENTORY_META:
      return { ...state, inventoryReportMeta: data };
    case action_types.REPORTING_NETSUITE_REPORT:
      return { ...state, netsuiteReport: data, netsuiteReportMeta: meta };
    default:
      return state;
  }
};
