import React, { useState, useEffect, Fragment } from 'react';
import { Button, Row, Col, Typography, Form, message, Spin, Space, Empty } from 'antd';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import RoleUsers from '../RoleUsers';
import { getSingleRole, addUserRole, updateUserRole, deleteUserRole } from '../../../ducks/services';
import { LoadingOutlined } from '@ant-design/icons';
import { InputField, SwitchField, CheckboxGroup } from 'Atoms/FormElement';
// import { allowed } from '../../../../../../../../routing/config/utils';
// import Roles from '../../../../../../../../routing/config/Roles';
import ModuleCheckbox from '../ModuleCheckbox';
import { Popup } from 'Atoms/Popup';

const antIcon = <LoadingOutlined spin />;
const { Title, Text } = Typography;

export default (props) => {

  const { title, onClose, roleData, reloadApi, allowedDelete } = props;
  const [teamData, setTeamData] = useState('');
  const [load, setLoad] = useState(false);
  const [userData, setUserData] = useState([]);
  const { control, formState: {errors}, setValue, reset, handleSubmit, getValues } = useForm();
  const users = useSelector((state) => state.systems.userRoles);
  const meta = useSelector((state) => state.systems.userRoleOption);
  const [rolesSelected, setSelectedRoles] = useState([]);
  const [resetNeutral, setResetNeutral] = useState(0);
  const [childNeutral, setChildNeutral] = useState(0);
  const isEdit = roleData.id ? true : false;
  const [visible, setVisible] = useState(false);
  const [searchLocationValue, setSearchLocationValue] = useState('');
  const [locationsMeta, setLocationsMeta] = useState([]);
  const [locationsData, setLocationsData] = useState([]);

  useEffect(() => {
    if (roleData.id) {
      setLoad(true);
      getSingleRole(roleData.id).then((response) => {
        let data = response?.data?.data;
        setTeamData(data);
        setUserData(data?.user_roles || []);
        setLoad(false);
        setLocationsData(data?.locations || []);
      });
    } else {
      setUserData([]);
      setTeamData('');
      setLocationsData([]);
      resetPermissions();
      setResetNeutral(old => old + 1);
      reset({ role_name: '' });
    }
  }, [roleData]);

  useEffect(() => {
    if (meta && 'permissions' in meta) {
      let temp = [];
      Object.entries(meta?.permissions)?.map(([key, val]) => {
        let temp2 = [];
        val.map(x => {
          temp2.push({
            role: x.permission_name,
            label: x.label,
            read_tips: x.read_tips
          })
        })
        temp.push({
          module: key + "-Module",
          label: key,
          screens: temp2
        })
      })
      setSelectedRoles(temp);
    }

    if(meta && 'locations' in meta) {
      setLocationsMeta(meta?.locations?.map(({id, desc, code}) => ({label: `${code} ${desc}`, value: id})))
    }
  }, [meta]);

  useEffect(() => {
    if (Object.entries(teamData).length) {
      setValue('role_name', teamData.name);
      // setValue('is_superadmin', teamData.is_superadmin === "1");
      if (teamData.permissions.length) {
        teamData?.permissions?.map((value) => {
          let screens = value.permissions;
          let module = value.name;
          let isall = value.all == 1;
          screens.map((val) => {
            setValue(`${val.permission_name}-read`, val.read == 1 ? [val.read] : []);
            setValue(`${val.permission_name}-write`, val.write == 1 ? [val.write] : []);
            setValue(`${val.permission_name}-delete`, val.delete == 1 ? [val.delete] : []);
            if (val.all == 1) {
              setValue(`${val.permission_name}`, true);
            } else {
              setValue(`${val.permission_name}`, false);
            }
          });
          setValue(`${module}-Module`, isall);
        });
      } else {
        reset({ role_name: teamData?.name });
      }
    }
  }, [teamData]);

  useEffect(() => {
    if(locationsData?.length) {
      const checkedLocations = locationsData.map(item => item.id)
      setValue('locations', checkedLocations)
    }
  }, [locationsData])

  const onFinish = (values) => {
    setLoad(true);
    let permissions = [];
    rolesSelected.map(item => {
      item.screens.map(value => {
       if (value.label == 'Is Manager' || value.label == 'Is Dean') {
          if (values[value.role] == true) {
            permissions.push({
              permission_name: value.role,
              read: 1,
              write: 1,
              delete: 1,
              all: 1,
              parent: item.label,
              label: value.label
            });
          } else {
            permissions.push({
              permission_name: value.role,
              read: 0,
              write: 0,
              delete: 0,
              all: 0,
              parent: item.label,
              label: value.label
            });
          }
        } else {
          permissions.push({
            permission_name: value.role,
            read: values[`${value.role}-read`] && values[`${value.role}-read`].length > 0 ? 1 : 0,
            write: values[`${value.role}-write`] && values[`${value.role}-write`].length > 0 ? 1 : 0,
            delete: values[`${value.role}-delete`] && values[`${value.role}-delete`].length > 0 ? 1 : 0,
            all: values[`${value.role}`] == true ? 1 : 0,
            parent: item.label,
            label: value.label
          });
        }
      })
    });

    const selectedLocations = values.locations.map(item => ({location_id: item}))

    const payload = {
      name: values.role_name,
      permission_items: JSON.stringify(permissions),
      // is_superadmin: values.is_superadmin ? 1 : 0,
      id: roleData?.id,
      // user_staff: userData.map((value) => ({ employee: value.name })),
      locations: JSON.stringify(selectedLocations),
    };

    !roleData.id
      ? addUserRole(payload)
        .then((response) => {
          setLoad(false);
          if (response.data.code == 200) {
            message.success("EMS Role added successfully");
            onClose();
            setTimeout(() => reloadApi(), 500);
          } else {
            message.error(response.data.message);
          }
        })
        .catch((error) => {
          setLoad(false);
          message.error(error?.message ?? 'Something went wrong');
        })
      : updateUserRole(payload)
        .then((response) => {
          setLoad(false);
          if (response.data.code == 200) {
            message.success("EMS Role updated successfully");
            onClose();
            setTimeout(() => reloadApi(), 500);
          } else {
            message.error(response.data.message);
          }
        })
        .catch((error) => {
          setLoad(false);
          message.error(error?.message ?? 'Something went wrong');
        });
  };

  const onDeleteUserRole = () => {
    setLoad(true);
    const payload = {
      id: roleData.id,
      status_event: 'remove',
    }
    deleteUserRole(payload)
      .then((response) => {
        setLoad(false);
        if (response.data.code == 200) {
          message.success("EMS Role deleted successfully");
          onClose();
          setTimeout(() => reloadApi(), 500);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => message.error('Cant delete this EMS role'));
  };

  const onCheckAll = (e, screen) => {
    // fix inputcheckbox not being updated (display checked)
    if (e == true) {
      screen.map(x => {
        setValue(`${x.role}`, true);
        setValue(`${x.role}-read`, [1]);
        setValue(`${x.role}-write`, [1]);
        setValue(`${x.role}-delete`, [1]);
      });
      setChildNeutral(old => old + 1);
    } else {
      screen.map(x => {
        setValue(`${x.role}`, false);
        setValue(`${x.role}-read`, []);
        setValue(`${x.role}-write`, []);
        setValue(`${x.role}-delete`, []);
      });
    }
  };

  const resetPermissions = () => {
    rolesSelected.map(item => {
      setValue(`${item.module}`, false);
      item.screens.map(value => {
        setValue(`${value.role}-read`, []);
        setValue(`${value.role}-write`, []);
        setValue(`${value.role}-delete`, []);
        setValue(`${value.role}`, false);
      });
    });
  };

  const openCancelPopUp = () => {
    setVisible(true);
  }

  const onConfirmDelete = async () => {
    onDeleteUserRole();
    setVisible(false);
  }

  const searchLocation = () => {
    if(searchLocationValue !== '') {
      setLocationsMeta(meta?.locations?.filter(item => item?.code?.toLowerCase()?.includes(searchLocationValue?.toLowerCase()) || item?.desc?.toLowerCase().includes(searchLocationValue.toLowerCase())).map(({id, desc, code}) => ({label: `${code} ${desc}`, value: id})))
    } else {
      setLocationsMeta(meta?.locations?.map(({id, desc, code}) => ({label: `${code} ${desc}`, value: id})))
    }
  }

  const cancelPopUp = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            Are you sure to delete the role?
          </Title>
          <Row gutter={10} justify={'center'}>
            <Col span={12}>
              <Button size='large' danger type="primary" className='w-100' onClick={() => {setVisible(false);}}>Cancel</Button>
            </Col>
            <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit' onClick={onConfirmDelete}>Confirm</Button></Col>
          </Row>
        </Space>
    </Spin>,
    width: 650,
    onCancel: () => {
      setVisible(false);
    },
  };

  return (
    <>
    <Spin indicator={antIcon} size="large" spinning={load}>
      <Form scrollToFirstError layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Row gutter={[24, 30]}>
          <Col span={24}>
            <Row gutter={24} justify="center">
              <Col>
                <Title level={3} className="mb-0">
                  {title}
                </Title>
              </Col>
            </Row>
          </Col>
          <Col span={isEdit ? 16 : 24}>
            {/* Role Access */}
            <Row gutter={[24, 18]}>
              <Col span={24}>
                <InputField
                  fieldname="role_name"
                  class="mb-0 w-100"
                  label="Role Name"
                  control={control}
                  iProps={{ placeholder: 'Type role name', size: 'large' }}
                  rules={{ required: 'Type role name' }}
                  initValue=""
                  validate={errors.role_name && 'error'}
                  validMessage={errors.role_name && errors.role_name.message}
                />
              </Col>
              {/* <Col span={24}>
                <SwitchField
                  fieldname="is_superadmin"
                  label="Is Superadmin?"
                  control={control}
                  iProps={{ size: 'large'}}
                  initValue={''}
                />
              </Col> */}
              <Col span={24}>
                <Text className="c-gray">Role Access</Text>
              </Col>
              {/* <Col span={24}>
                <Input placeholder="Type permission name" onChange={(e) => setSearchPermission(e.target.value)} />
              </Col> */}
              {rolesSelected.map((item, index) => (
                <Fragment key={index}>
                  <ModuleCheckbox
                    control={control}
                    item={item}
                    setValue={setValue}
                    getValues={getValues}
                    onCheckAll={onCheckAll}
                    data={teamData?.permissions}
                    resetNeutral={resetNeutral}
                    childNeutral={childNeutral}
                  />
                </Fragment>
              ))}
            </Row>

            {/* Location Access */}
            <Row gutter={[24, 18]} style={{marginTop: '20px'}}>
              <Col>
                <Text className="c-gray">Location Access</Text>
              </Col>

              <Row className='w-100' gutter={[10, 10]}>
                <Col flex="auto">
                  <InputField
                    fieldname='search'
                    label=''
                    class='mb-0 w-100'
                    initValue={''}
                    control={control}
                    iProps={{ placeholder: 'Search for locations' }}
                    onChange={(e) => setSearchLocationValue(e.target.value)}
                  />
                </Col>
                <Col flex="70px">
                  <Button className='w-100' size='large' type='primary' onClick={searchLocation}>Search</Button>
                </Col>
              </Row>

              {/* locationOptions */}
              <Col span={24}>
                {locationsMeta?.length === 0 ?
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  :
                  <CheckboxGroup
                    // disabled={!allowedEdit}
                    fieldname={'locations'}
                    label={''}
                    class={`mb-1 fullWidth-checbox`}
                    control={control}
                    initValue={[]}
                    option={locationsMeta}
                    rules={{
                      required: 'Location is required',
                    }}
                    validate={errors.locations && 'error'}
                    validMessage={errors.locations && errors.locations.message}
                  />
                }
              </Col>
            </Row>
          </Col>
          <Col span={isEdit ? 8 : 24}>
            <Row gutter={[24, 20]}>
              {
                isEdit &&
                <Col span={24}>
                  <RoleUsers
                    userData={userData}
                    setUserData={setUserData}
                    title="Assigned Users"
                    control={control}
                    allListing={users}
                  />
                </Col>
              }
              <Col span={24}>
                <Row gutter={24}>
                  {roleData.id ? (
                    <>
                      {allowedDelete && roleData?.is_superadmin === "0" && (
                        <Col span={12}>
                          <Button
                            size="large"
                            type="primary"
                            htmlType="button"
                            className="red-btn w-100"
                            onClick={openCancelPopUp}
                          >
                            Delete
                          </Button>
                        </Col>
                      )}
                      {
                        roleData?.is_superadmin === "0" &&
                        <Col span={12}>
                          <Button size="large" type="primary" htmlType="submit" className="green-btn w-100">
                            Save
                          </Button>
                        </Col>
                      }
                    </>
                  ) : (
                    <>
                      <Col span={12}>
                        <Button
                          size="large"
                          type="primary"
                          htmlType="button"
                          className="black-btn w-100"
                          onClick={onClose}
                        >
                          Close
                        </Button>
                      </Col>
                      <Col span={12}>
                        <Button size="large" type="primary" htmlType="submit" className="green-btn w-100">
                          Add
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
    <Popup {...cancelPopUp} />
    </>
  );
};
