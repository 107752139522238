import React, { useEffect, Fragment, useState } from 'react';
import { Button, Row, Col, Form, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';

const _ = require('lodash');

function removeUnderscoreAndCapitalize(value) {
  if(value) return value.split("_").map(item => `${item.slice(0, 1).toUpperCase()}${item.slice(1)}`).join(' ')
  return value
}

export default (props) => {
  const { id, data, meta, addStockTemplate, editStockTemplate, reloadApi, mode, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();

  const formFields = [
    {
      name: 'code',
      label: 'Code',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'desc',
      label: 'Description',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      type: 'select',
      label: 'Stock Template Type',
      name: 'stock_template_type',
      twocol: false,
      options: meta?.stock_template_types?.map((x) => ({ label: removeUnderscoreAndCapitalize(x), value: x })),
      req: true,
      placeholder: 'Please select',
      reqmessage: 'Stock Template Type Required',
      colWidth: '0 1 50%',
      static: !allowedEdit
    },
    {
      name: 'order_type_group',
      label: 'Recipe',
      req: false,
      placeholder: 'Please select',
      type: 'select',
      class: 'default-select',
      options: meta?.order_type_groups?.map(y => ({ label: `${y.code} ${y.desc}`, value: y.id })),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
    },
  ];

  useEffect(() => {
    if (data && 'id' in data && mode !== 'add') {
      setValue('code', data?.code);
      setValue('desc', data?.desc);
      setValue('stock_template_type', data?.stock_template_type ? { label: removeUnderscoreAndCapitalize(data.stock_template_type), value: data.stock_template_type } : '');
      setValue('order_type_group', data?.order_type_group_id ? {label: `${data?.order_type_group_code} ${data?.order_type_group_desc}`, value: data.order_type_group_id} : "");
    }
  }, [data]);

  const onFinishAdd = async (val) => {
    props.setLoading(true);

    const payload = {
      ...val,
      stock_template_type: val?.stock_template_type?.value,
      order_type_group_id: val?.order_type_group?.value,
    }

    await addStockTemplate(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Stock Template created successfully!");
        reloadApi(res.data.data);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  const onFinish = async (val) => {
    if(mode === 'add') {
      return onFinishAdd(val);
    }
    props.setLoading(true);
    const payload = {
      ...val,
      stock_template_type: val?.stock_template_type?.value,
      order_type_group_id: val?.order_type_group?.value,
      stock_template_id: id,
    }

    await editStockTemplate(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Stock Template updated successfully!");
        setTimeout(() => reloadApi(res.data.data), 250);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>Save</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}