import axios from 'Services/axiosInterceptor';
import { externalBaseUrl } from '../../../../configs/constants';

export const addOutlet = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/create`, payload);
};

export const editOutlet = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/update`, payload);
};

export const editOutletReceipt = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/update-receipt`, payload);
};

export const removeOutlet = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/update-status`, payload);
};

export const bulkRemoveOutlets = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/bulk-update-status`, payload);
};

export const bulkUpdateRegion = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/bulk-update-region`, payload);
}

export const duplicateOutlet = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/duplicate`, payload);
};

export const addOutletGroup = (payload) => {
  return axios.post(`${externalBaseUrl}/location-groups/create`, payload);
};

export const editOutletGroup = (payload) => {
  return axios.post(`${externalBaseUrl}/location-groups/update`, payload);
};

export const removeOutletGroup = (payload) => {
  return axios.post(`${externalBaseUrl}/location-groups/update-status`, payload);
};

export const bulkRemoveOutletGroups = (payload) => {
  return axios.post(`${externalBaseUrl}/location-groups/bulk-update-status`, payload);
};

export const updateOutletEmsUser = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/update-ems-user`, payload);
}

export const addOutletPosUser = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/pos-user-create`, payload);
}

export const removeOutletUser = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/pos-user-update-status`, payload);
}

export const bulkRemoveOutletUsers = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/pos-user-bulk-update-status`, payload);
}

export const addBlockedOrderType = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/blocked-order-type-add`, payload);
}

export const removeBlockedOrderType = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/blocked-order-type-update-status`, payload);
}

export const bulkRemoveBlockedOrderType = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/blocked-order-type-bulk-update-status`, payload);
}

export const addBlockedProduct = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/blocked-product-add`, payload);
}

export const editBlockedProduct = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/blocked-product-update`, payload);
}

export const removeBlockedProduct = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/blocked-product-update-status`, payload);
}

export const bulkRemoveBlockedProduct = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/blocked-product-bulk-update-status`, payload);
}

export const clearOutletData = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/clear-outlet-data`, payload);
}

export const updateFaIntegration = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/update-fa-integration`, payload);
};

export const updateMallIntegration = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/update-mall-integration`, payload);
};

export const generateMallIntegration = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/generate-mall-integration`, payload);
};

export const resyncSingleFa = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/resync-single-fa`, payload);
};

export const resendUploadFile = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/resend-upload-file`, payload);
};

export const generateUploadFile = (payload) => {
  return axios.post(`${externalBaseUrl}/locations/generate-upload-file`, payload);
};