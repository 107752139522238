import React, { Fragment } from 'react';

export default (props) => {
  const { columns, data } = props;

  return (
    <div className="inventory-table-container">
      <div className="inventory-table">
        <table>
          <thead>
            <tr>
              {columns?.stock_movement_listing?.list?.map(item =>
                <th className={`${item?.longCol ? 'long-col' : ''} text-${item.align}`} key={`Title ${item.title}`}>
                  {item.title}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data?.list?.map((listItem, listItemIndex) =>
              <Fragment key={`List Item ${listItemIndex}`}>
                {listItem?.list?.map((childListItem, childListItemIndex) => 
                  <tr key={`List Item ${listItemIndex} Child List Item ${childListItemIndex}`}>
                    <td>{childListItem.product_code}</td>
                    <td>{listItem.location}</td>
                    <td>{childListItem.uom}</td>
                    <td>{childListItem.date}</td>
                    <td>{childListItem.id}</td>
                    <td>{childListItem?.reference}</td>
                    <td>{childListItem.transaction}</td>
                    <td>{childListItem.status}</td>
                    <td align='right'>{parseFloat(childListItem.qty).toFixed(5)}</td>
                    <td align='right'>{parseFloat(childListItem.running_balance).toFixed(5)}</td>
                    <td align='right'>{parseFloat(childListItem.cost).toFixed(8)}</td>
                    <td align='right'>{parseFloat(childListItem.running_cost).toFixed(8)}</td>
                  </tr>
                )}
                {
                  data?.list?.length > 1 &&
                  <tr className='bg-gray text-white'>
                    <td colSpan={7}></td>
                    <td>Sub Total</td>
                    {listItem?.sub_totals?.map((item, index) =>
                      <td key={`Sub Total ${item} ${index}`} align='right'>{item}</td>
                    )}
                    <td colSpan={6}></td>
                  </tr>
                }
              </Fragment>
            )}

            <tr className='bg-gray text-white'>
              <td colSpan={7}></td>
              <td>Grand Total</td>
              {data?.grand_total?.map((item, index) => 
                <td key={`Grand Total ${item} ${index}`} align='right'>{item}</td>
              )}
              <td colSpan={6}></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}